<template>
<!-- 预售详情信息 -->
  <div id="page">
    <div class="nav">
      <ul>
        <li v-for="(item,index) in headernav" :key="index"
        @click="this.$router.push(item.path)">
          {{item.text}}
        </li>
      </ul>
      <div id="search">
        <div class="logosearch">
          <div id="logo">
            <img @click="this.$router.push('/')" src="../../assets/logo2.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="header">
        <div class="left_img">
          <img :src="thisimgurl" alt="" class="detailimg">
          <div class="carousel_div">
            <button @click="carDivBtnNextLast('last')"
            class="car_left_button is_carbutton"><el-icon><arrow-left /></el-icon></button>
              <ul class="car_img_ul">
                <li class="car_img_li"
                v-for="(i,index) in this.product.albumPics" :key="index">
                  <span class="li_span_img"
                  :class="isactCarimg==index?'isact_carimg':''"
                  @click="isact(index,'isactCarimg')"><img :src="i" alt=""></span>
                </li>
              </ul>
            <button @click="carDivBtnNextLast"
            class="car_right_button is_carbutton"><el-icon><arrow-right /></el-icon></button>
          </div>
        </div>
        <div class="center_car">
          <div class="center_title">
            <span class="yushouCont">{{$t('m.reservation.Pre_sale')}}</span>
            <h4 class="titlefont">{{product.name}}</h4>
          </div>
          <el-form ref="datalist" :model="datalist" label-width="500px">
            <el-form-item :label="$t('m.reservation_varietiy_details.Price')" label-width="100px">
              <span class="saleclass">
                {{product.currencySymbol+thisStock.price+'/'+product.unit}}
              </span>
            </el-form-item>
            <div class="itemdiv">
              <span class="itemdiv_span">
                {{$t('m.reservation_varietiy_details.Buyer_priority_to_pay')}}
                <strong>{{product.prePayRate+'%'}}</strong>
                {{$t('m.reservation_varietiy_details.deposit')}}</span>
            </div>
            <el-form-item :label="$t('m.reservation_varietiy_details.Stock')" label-width="100px">
              <span class="stock_num">{{thisStock.stock + product.unit}}</span>
              <div class="dinghuo"><i class="">{{$t('m.reservation_varietiy_details.Minimum_Order_Quantity')}}:</i>{{product.minBuy + product.unit}}</div>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Validity_of_Quotation')" label-width="100px">
              <span>{{product.offerExpiryDate}}</span>
              <div class="dinghuo"><i class="">{{$t('m.reservation_varietiy_details.Minimum_Increment')}}:</i>{{product.minIncr + product.unit}}</div>
            </el-form-item>
            <div class="yuti">
              <span class="tian">{{$t('m.reservation_varietiy_details.Advanced_days_of_delivery')}}
                <i>
                  {{thisBeginDay}}
                  -
                  {{thisEndDay}}{{$t('m.reservation_varietiy_details.day')}}</i>
              </span>
              <span class="zhuanshouspan">{{product.isResale==1?$t('m.reservation_varietiy_details.Resell_allowed'):$t('m.reservation_varietiy_details.Resale_not_allowed')}}
                <el-icon><warning-filled /></el-icon>
              </span>
              <span class="spanzs" v-if="product.isResale==1">{{$t('m.reservation_varietiy_details.Permission_to_resell_before_advance_delivery')}}</span>
            </div>
            <el-form-item :label="$t('m.reservation_varietiy_details.Selective_specification')" label-width="100px">
              <div class="guige">
                <el-tooltip placement="top" effect="light"
                  v-for="(i,index) in newSkuStockList" :key="index"
                  :class="isactive==index?'isactive':''">
                  <template #content>
                    <div style="display:flex; align-items: center;">
                      <img :src="i.pic" style="width:80px;height:80px;">
                      <div>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          {{$t('m.reservation_varietiy_details.guigeName')}}:
                          <span style="color:#333333;">
                            {{i.spData[1].value?i.spData[1].value:i.spData[0].value}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          {{$t('m.reservation_varietiy_details.guigeStock')}}：<span style="color:#333333;">
                            {{i.stock+product.unit}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          {{$t('m.reservation_varietiy_details.guigePrice')}}：<span style="color:#333333;">
                            {{product.currencySymbol+i.price+'/'+product.unit}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          {{$t('m.reservation_varietiy_details.guigepickup_date')}}：<span style="color:#333333;">
                            {{i.spData[0].value}}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <span class="btn" @click="isact(index, 'isactive', i)"
                  v-show="index<=3?true:showallgg"
                  >
                    {{i.spData[1].value?i.spData[1].value:i.spData[0].value}}
                  </span>
                </el-tooltip>
              </div>
              <span class="showallsp" v-if="newSkuStockList.length>4"
                @click="this.showallgg=!showallgg">
                  <el-icon>
                    <component :is="showallgg?'ArrowUpBold':'ArrowDownBold'"></component>
                  </el-icon>
                </span>
            </el-form-item>
            <el-form-item class="tihuo_time" :label="$t('m.reservation_varietiy_details.Date_of_Taking_Delivery')" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <div class="block">
                <el-date-picker
                v-model="tihuoValue"
                editable
                size="small"
                type="date"
                @change="handleAddOne"
                :placeholder="$t('m.reservation_varietiy_details.Select_the_date_of_taking_delivery')"
                :disabledDate="disabledDate"
                value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </div>
              <div class="spanlist">
                <el-tag v-for="(i, item) in timeTags" :key="item"
                  closable
                  v-show="item<=1?true:showallsp"
                  size="small"
                  @close="handleClose(i, timeTags)">
                  {{i}}
                </el-tag>
              </div>
              <span class="showallsp" v-if="timeTags.length>2"
                @click="this.showallsp=!showallsp"
                >
                  <el-icon>
                    <component :is="showallsp?'ArrowUpBold':'ArrowDownBold'"></component>
                  </el-icon>
              </span>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.number')" label-width="100px">
              <el-input-number
                v-model="thisBuyNum"
                :step="product.minIncr"
                size="mini"
                :min="Number(product.minBuy)"
                :max="thisStock.stock"
                @change="changeNum(thisBuyNum)"/>
            </el-form-item>
          </el-form>
          <span class="btnspan">
            <div class="btn_div">
              <button class="gobutton gm" :style="`left:${$t('m.reservation_varietiy_details.attentionLeft')}`"
                  @click="toBuyPage">
                  {{$t('m.reservation_varietiy_details.purchase')}}
              </button>
              <button class="gobutton gz" :style="`left:${$t('m.reservation_varietiy_details.purchaseLeft')}`"
                  @click="likeVarietiy">
                  {{$t('m.reservation_varietiy_details.attention')}}
              </button>
            </div>
          </span>
        </div>
        <div class="right_test">
          <h3 class="righttitle">{{$t('m.reservation_varietiy_details.Estimated_price')}}</h3>
          <el-form class="right_form"
          label-suffix=":"
          ref="datalist" label-width="259px">
            <el-form-item :label="$t('m.reservation_varietiy_details.est_select_specifications')" label-width="80px">
              <el-select
                size="mini" value-key="id"
                v-model="thisRightTest.guigevalue"
                :placeholder="$t('m.reservation_varietiy_details.Please_select_specifications')">
                  <el-option
                    v-for="(i,k) in skuStockList" :key="k"
                    :label="$t('m.reservation_varietiy_details.specification')+`:`+i.spData[1].value+` / `+$t('m.reservation_varietiy_details.delivery_day')+`:`+i.spData[0].value"
                    :value="i">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Purchased_weight')" label-width="80px">
              <el-input-number
                v-model="thisRightTest.weightvalue"
                :step="product.minIncr"
                size="small"
                :min="Number(product.minBuy)>thisStock.stock?thisStock.stock:product.minBuy"
                :max="thisStock.stock"/>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Delivery_method')" label-width="80px">
              <el-select
                size="mini" value-key="id"
                v-model="thisRightTest.yutivalue"
                :placeholder="$t('m.reservation_varietiy_details.Select_withholding_mode')">
                  <el-option
                    v-for="(i,k) in pmsProductPreTakeWayList" :key="k"
                    :label="i.spec+product.currencySymbol+i.price"
                    :value="i">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Prepayment_ratio')" label-width="80px">
              <span>{{thisRightTest.fukuanvalue+'%'}}</span>
            </el-form-item>
          </el-form>
          <div class="jisuan">
            <p>{{$t('m.reservation_varietiy_details.pick_up_goods_cost')}}：<span>{{product.currencySymbol}}{{Number(thisRightTest.yutivalue.price*thisRightTest.weightvalue).toFixed(2)}}</span></p>
            <p>{{$t('m.reservation_varietiy_details.advance_payment')}}：<span>{{product.currencySymbol}}
              {{Number((thisRightTest.guigevalue.price * thisRightTest.weightvalue / 100) * this.thisRightTest.fukuanvalue).toFixed(2)}}
              </span></p>
            <p>{{$t('m.reservation_varietiy_details.final_payment')}}：<span>{{product.currencySymbol}}
              {{daikuan.toFixed(2)}}
              </span></p>
          </div>
          <div class="heji">
            {{$t('m.reservation_varietiy_details.Estimated_total_payment')}}：<span>{{product.currencySymbol}}
              {{Number(allPrice).toFixed(1)}}</span>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="supplier_message">
          <h3>{{$t('m.reservation_varietiy_details.Supplier_file')}}</h3>
          <p class="supname">{{companyInfo.companyName}}</p>
          <!-- <span class="supgood">好评率：{{(99.5561).toFixed(2)}}%</span> -->
          <div class="supxiangxi">
            <div class="suplogo">
              <img :src="companyInfo.logo" alt="">
            </div>
            <div class="xxdiv">
              <el-icon><location /></el-icon>
              <span>{{companyInfo.address}}</span></div>
            <div class="xxdiv">
              <el-icon><phone-filled /></el-icon>
              {{companyInfo.phoneNumber}}</div>
            <div class="xxdiv">
              <el-icon><avatar /></el-icon>
              <el-popconfirm
                confirm-button-text="是的"
                cancel-button-text="再考虑一下"
                icon="InfoFilled"
                icon-color="#626AEF"
                :title="product.focusOnMerchant==1?'你要取关该供应商吗?':'你要关注该供应商吗?'"
                @confirm="confirmEvent"
                @cancel="cancelEvent"
              >
                <template #reference>
                  <el-button size="mini">{{product.focusOnMerchant==1?$t('m.reservation_varietiy_details.followed'):$t('m.reservation_varietiy_details.attention')}}</el-button>
                </template>
              </el-popconfirm>
            </div>
            <div class="xxdiv">
              <el-icon><ChatDotRound /></el-icon>
              <!-- <el-button size="mini" @click="goToMessage()">联系</el-button> -->
              <CreateChat :productData='{
                productInfo: this.productInfo,
                merchantId: this.product.merchantId,
                productId:this.product.id,
                companyName:this.companyInfo.companyName
              }' />
            </div>
          </div>
        </div>

        <div class="supplier_commodity">
          <h2>{{$t('m.reservation_varietiy_details.Coffee_details')}}</h2>
          <div class="coffee_mes">
            <ul>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.farm')}}：</h4>
                <span class="li_mmsg">{{product.manorName}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.place_of_origin')}}：</h4>
                <span class="li_mmsg">{{product.originPlace}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.year')}}：</h4>
                <span class="li_mmsg">{{product.productTime}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.elevation')}}：</h4>
                <span class="li_mmsg">{{product.altitude + '米'}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.Picking_method')}}：</h4>
                <span class="li_mmsg">{{product.pickWay}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.way_of_quoting')}}：</h4>
                <span class="li_mmsg">{{product.offerForm}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>{{$t('m.reservation_varietiy_details.processing_method')}}：</h4>
                <span class="li_mmsg">{{product.processWay}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>{{$t('m.reservation_varietiy_details.Coffee_flavor')}}：</h4>
                <span class="li_mmsg">{{product.flavorDescription}}</span>
              </li>
            </ul>
          </div>
          <div class="commodity_message">
            <el-breadcrumb separator="|">
              <el-breadcrumb-item
              v-for="i in commMess" :key="i">
                <div class="com_body">
                  <span>{{i.cont}}</span>
                  <p>{{i.title}}</p>
                </div>
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>promotion management</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <h2>{{$t('m.reservation_varietiy_details.commodity_details')}}</h2>
          <div class="commodity_content">
            <p class="cont_message">
              {{product.detailDesc}}
            </p>
            <div class="cont_img" v-html="product.detailHtml">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from '../index/Footer.vue'
import { getStore, setStore } from '../../tools/storage.js'

import { toRaw } from 'vue'
import { reactive, ref } from 'vue-demi'
import http from '../../api/http.js'
// import { useRoute } from 'vue-router'
// import calcuTime from '../../tools/calculateTime.js'
// import { addchatGroup, sellerlist } from '../../common/InForMessage.js'
import { ElMessage } from 'element-plus'
import moment from 'moment'
import CreateChat from '../../components/chatroom/createChat.vue'
import i18n from '@/tools/i18n.js'
const { t } = i18n.global

const headernav = [
  { text: t('m.Header.home_page'), path: '/' },
  { text: t('m.search.login'), path: '/loginandregister' },
  { text: t('m.Header.mobile_terminal'), path: '' }
]
// 关注商品接口
const followTheProduct = (data) => {
  return http.post('/mall-portal/mall/pmsfollowtheproduct/followTheProduct', data)
}
// 关注店铺接口
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
export default {
  name: 'VarietiyDetails',
  data () {
    return {
      endTime: '',
      previewStatus: 1,
      isactive: ref(0),
      isactCarimg: ref(0),
      thisimgurl: '', // 选择的图片
      thisStock: 0, // 选择的规格（库存）
      thisBeginDay: 0, // 预提提前开始几天
      thisEndDay: 0, // 预提提前开始几天
      thisBuyNum: ref(0), // 购买数量

      atime: [],
      thisRightTest: reactive({
        guigevalue: {
          price: 0
        },
        weightvalue: 0,
        yutivalue: {
          price: 0
        },
        fukuanvalue: ''
      }), // 右侧预估价格
      payoption: [{}], // 付款比例
      // daikuan: 0,
      yunfei: 0,
      allPrice: ref(0), // 预估合计
      commMess: [
        { title: this.$t('m.reservation_varietiy_details.brand'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.date_issued'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.storage_life'), cont: '12个月' },
        { title: this.$t('m.reservation_varietiy_details.place_of_dispatch'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.delivery_points'), cont: '' }
      ], // 详情头部信息
      datalist: reactive({}),
      guigevalue: '',
      showallgg: ref(false),
      timeTags: reactive([]), // 所选日期
      showallsp: ref(false),
      tihuoValue: '', // 默认显示时间
      // comList: reactive({})
      brand: '',
      companyInfo: '',
      couponList: [],
      pmsProductContractList: [],
      pmsProductPreTakeWayList: [],
      pmsSiteEntityHashMap: [],
      pmsSiteEntity: '',
      product: '',
      productAttributeList: [],
      productAttributeValueList: [],
      productFullReductionList: [],
      productLadderList: [],
      productPresaleExtraList: [],
      redPacketList: [],
      skuStockList: [],
      newSkuStockList: [], // 处理后展示的规格
      newSkuAllList: [], // 同一规格不同时间展示的数据
      isGuiGechose: '',
      // oldSkuStockList: [] // 所有原数据

      // 聊天窗口信息
      productInfo: {}

      // chatDialog: false,
      // merchantId: '',
      // sellerlist: [] // 客服列表
    }
  },
  components: { Footer, CreateChat },
  watch: {
    'thisRightTest.guigevalue' (v, oldv) {
      this.allPrice = (v.price * this.thisRightTest.weightvalue) + (this.thisRightTest.yutivalue.price * this.thisRightTest.weightvalue)
    },
    'thisRightTest.weightvalue' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.guigevalue.price) + (this.thisRightTest.yutivalue.price * v)
    },
    'thisRightTest.yutivalue' (v, oldv) {
      this.allPrice = ((this.thisRightTest.weightvalue * this.thisRightTest.guigevalue.price) + (v.price * this.thisRightTest.weightvalue))
    }
  },
  computed: {
    daikuan: {
      get () {
        return (this.allPrice - (this.thisRightTest.guigevalue.price * this.thisRightTest.weightvalue / 100) * this.thisRightTest.fukuanvalue)
      }
    }
  },
  methods: {
    isact (k, name, item) {
      switch (name) {
        case 'isactCarimg':
          this.isactCarimg = k
          this.thisimgurl = this.product.albumPics[k]
          break
        case 'isactive':
          // console.log(k)
          // console.log(name)
          this.isGuiGechose = item.spData[1].value
          // console.log(item)
          this.endTime = item.spData[1].value
          this.isactive = k
          this.timeTags = [] // 选择的时间
          this.tihuoValue = ''
          this.thisBuyNum = this.product.minBuy // 切换规格，数量变成最小订货量
          this.atime = [] // 可以预提的时间
          this.newSkuAllList = [] // 规格展示 list
          // console.log(item)
          // this.thisStock = item // 选择的规格参数
          console.log(this.skuStockList)
          for (const i of this.skuStockList) { // 把所有当前选择的规格日期添加进去
            if (this.endTime === i.spData[1].value) {
              this.newSkuAllList.push(i)
              this.atime.push(i.spData[0].value)
            }
          }
          console.log(this.newSkuAllList)
          break
        default:
          break
      }
    },
    carDivBtnNextLast (sta) {
      if (sta === 'last') {
        this.isactCarimg === 0 ? this.isactCarimg = this.product.albumPics.length - 1 : this.isactCarimg--
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      } else {
        this.isactCarimg === this.product.albumPics.length - 1 ? this.isactCarimg = 0 : this.isactCarimg++
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      }
    },
    // 删除所选项 ,或者重置已选择
    handleClose (tag, tagslist) {
      // console.log(typeof tag)
      // console.log(tag)
      if (typeof tag !== 'string') {
        this.timeTags.splice(0, tag.length)
      } else {
        this.timeTags.splice(tagslist.indexOf(tag), 1)
      }
    },
    handleAddOne (tag) { // 单选添加日期
      console.log(tag)
      this.timeTags = [] // 当前仅支持单选
      for (const i of this.newSkuAllList) { // 把所有当前选择的规格日期添加进去
        // console.log(i)
        if (tag === i.spData[0].value) {
          // console.log('规格11111')
          console.log(i)
          this.thisStock = i
          // this.thisStock.stock = i.stock
          // this.thisStock.price = i.stock
          // this.thisStock.id = i.id
          // this.thisStock.skuCode = i.skuCode
          break
        }
      }
      console.log(this.thisStock)
      if (tag === null) {
        return
      }
      // for (let i = 0; i < this.timeTags.length; i++) {
      //   // console.log(tagslist[i])
      //   if (this.timeTags[i] === tag) {
      //     // this.tags[i] = tag
      //     return
      //   }
      // }
      this.timeTags.push(tag)
      console.log(this.timeTags)
    },
    disabledDate (time) { // 日期限制
      // console.log(time)
      // console.log(moment(time).format('YYYY-MM-DD'))
      time = moment(time).format('YYYY-MM-DD')
      // 开始时间，结束时间限制
      // return time.getTime() > (Date.parse(this.atime.beginDate)) ||
      //   time.getTime() < Date.parse(this.atime.finishDate) - (24 * 60 * 60 * 1000)

      return this.atime.indexOf(time) === -1
    },
    toBuyPage () {
      // console.log(toRaw(this.thisStock))
      // console.log(JSON.stringify(toRaw(this.timeTags)))
      if (this.timeTags.length > 0) {
        let data = []
        if (this.previewStatus === 1) {
          data = [{
            resaleProductId: this.product.id,
            resaleFlag: 0,
            SkuId: this.thisStock.id,
            SkuCode: this.thisStock.skuCode,
            number: this.thisBuyNum
          }]
        } else {
          data = [{
          // resaleProductId: this.product.id,
            resaleFlag: 1,
            SkuId: this.thisStock.id,
            SkuCode: this.thisStock.skuCode,
            number: this.thisBuyNum
          }]
        }
        console.log(data)
        console.log(this.skuStockList)
        if (this.timeTags.length > 1) {
          for (const i of this.timeTags) {
            console.log(i)
          }
        }

        http.post('/mall-order/order/datePresaleGenerateConfirmOrder', data, true)
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              try {
                const loading = this.$loading({
                  lock: true,
                  text: '加载中'
                })
                setTimeout(() => {
                  setStore('confirmordermessage', JSON.stringify(res.data.data))
                  // console.log(typeof data[0].resaleFlag)
                  this.$router.push({
                    path: '/buy',
                    query: {
                      name: this.product.name,
                      previewStatus: res.data.data.pmsProduct.previewStatus,
                      resaleFlag: data[0].resaleFlag,
                      time: encodeURIComponent(JSON.stringify(toRaw(this.timeTags)))
                    // prodata: encodeURIComponent(JSON.stringify(res.data.data))
                    }
                  })
                  // window.open(this.$router.href)
                  loading.close()
                }, 1000)
              } catch (error) {
                console.log(error)
              }
            } else {
              // ElMessage.error({ message: res.data.msg })
            }
          }).catch(err => {
            console.log(err)
          })
      } else {
        ElMessage.error({ message: '请选择至少一个提货日期' })
      }
    },
    unique (arr1) { // 去重方法
      const newArr = []
      arr1.forEach(j => {
        var flag = false
        // console.log(j.spData)
        for (const i of newArr) {
          if (j.spData[1].value === i.spData[1].value) {
            // i.stock += j.stock
            flag = true
            break
          }
        }
        if (!flag) {
          newArr.push(j)
        }
      })
      for (const j of arr1) {
        for (let i of newArr) {
          if (i.spData[1].value === j.spData[1].value) {
            // console.log(j)
            if (moment(j.spData[0].value).isAfter(i.spData[0].value)) {
              i = j
            }
          }
        }
      }

      // const res = new Map()
      // // console.log(res)
      // arr1.filter(
      //   (item) => !res.has(item.spData[1].value) && res.set(item.spData[1].value, 1)
      // )
      // console.log(arr1)
      // console.log(newArr)
      return newArr
    },
    changeTime (ggtime) { // 处理规格 超出预提时间的数据
      console.log(ggtime)
      const now = moment(new Date()).format('YYYY-MM-DD')
      const overTime = ggtime
      // console.log(now)
      const lastbegin = moment(overTime, 'YYYY-MM-DD')
        .subtract(this.thisBeginDay, 'days')
        .format('YYYY-MM-DD')
      const lastend = moment(overTime, 'YYYY-MM-DD')
        .subtract(this.thisEndDay, 'days')
        .format('YYYY-MM-DD')

      // console.log(lastbegin, lastend)
      // 判断是否当前日期是否 符合可预提时间
      const timestatus = moment(now).isBetween(lastend, lastbegin, 'days', '[]') ||
      moment(lastbegin).isSameOrAfter(now, 'days')

      console.log(timestatus)
      return timestatus
    },
    changeNum (sum) {
      // console.log(sum)
      // const maxStock = this.thisStock.stock
      const minBuy = this.product.minBuy
      const minIncr = this.product.minIncr
      const zengGou = sum - minBuy
      // if (sum >= maxStock - minBuy) {
      //   this.thisBuyNum = maxStock
      //   // console.log(this.thisBuyNum)
      //   return
      // }
      if (zengGou >= minIncr) {
        console.log(Math.ceil(zengGou / minIncr))
        this.thisBuyNum = minBuy + Math.ceil(zengGou / minIncr) * minIncr
        // if (maxStock - this.thisBuyNum < minBuy) {
        //   this.thisBuyNum = maxStock
        // } else {
        //   // this.thisBuyNum = maxStock - minBuy
        // }
      } else {
        this.thisBuyNum = minIncr
      }
      console.log(this.thisBuyNum)
    },
    async likeVarietiy () { // 关注商品
      const data = {
        previewStatus: 1,
        productId: null,
        productSn: this.$route.query.productSn
      }
      const res = await followTheProduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async confirmEvent () { // 关注供应商
      // console.log('confirm!')
      const data = {
        merchantId: this.companyInfo.memberId
      }
      if (this.product.focusOnMerchant === 1) {
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '取关成功'
          })
          this.product.focusOnMerchant = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '关注成功!'
          })
          this.product.focusOnMerchant = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    cancelEvent () {
      // 取消操作供应商
    }
  },
  mounted () {
    // console.log(this.$route.query)
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部

    const loading = this.$loading({
      lock: true,
      text: '查询中...'
    })
    const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
    console.log(userId)
    const data = {
      productSn: this.$route.query.productSn,
      userId: userId ? userId.id : null
    }
    http.get('/mall-portal/product/detailBySn', data)
      .then(res => {
        console.log(res.data.data)
        this.comList = res.data.data
        // ========
        this.brand = this.comList.brand
        this.companyInfo = this.comList.companyInfo
        this.couponList = this.comList.couponList
        this.pmsProductContractList = this.comList.pmsProductContractList
        this.pmsProductPreTakeWayList = this.comList.pmsProductPreTakeWayList
        this.pmsSiteEntityHashMap = this.comList.pmsSiteEntityHashMap
        this.pmsSiteEntity = this.comList.pmsSiteEntity
        this.product = this.comList.product
        this.productInfo = this.comList.product
        this.productAttributeList = this.comList.productAttributeList
        this.productAttributeValueList = this.comList.productAttributeValueList
        this.productFullReductionList = this.comList.productFullReductionList
        this.productLadderList = this.comList.productLadderList
        this.productPresaleExtraList = this.comList.productPresaleExtraList
        this.redPacketList = this.comList.redPacketList
        this.skuStockList = this.comList.skuStockList
        // this.oldSkuStockList = this.comList.skuStockList
        // console.log(this.skuStockList)
        // 赋值给原数据
        this.product.albumPics = this.comList.product.albumPics.split(',') // 轮播图
        this.thisimgurl = this.product.albumPics[0]
        this.thisBeginDay = this.productPresaleExtraList[0].advancePikegoodsDaysBegin
        this.thisEndDay = this.productPresaleExtraList[0].advancePikegoodsDaysEnd

        this.commMess[0].cont = this.product.brandName
        this.commMess[1].cont = this.product.createTime
        // this.commMess[2].cont = this.product.description
        this.commMess[3].cont = this.pmsSiteEntity.name
        this.commMess[4].cont = this.pmsSiteEntity.name

        this.thisBuyNum = this.product.minBuy // 最少购买数量
        this.thisRightTest.fukuanvalue = this.product.prePayRate // 预估付款比例

        this.previewStatus = this.product.previewStatus // 是否是预售 0->不是；1->是

        const arrskuStockList = []
        for (const i of this.skuStockList) {
          i.spData = JSON.parse(i.spData) // 给规格转换格式
          if (this.changeTime(i.spData[0].value)) {
            this.newSkuStockList.push(i)
            arrskuStockList.push(i)
            // console.log(i)
          }
        }
        this.skuStockList = arrskuStockList
        // console.log(this.newSkuStockList)
        this.newSkuStockList = this.unique(this.newSkuStockList) // 处理后的规格
        // console.log(this.newSkuStockList)

        if (this.newSkuStockList.length === 0) {
          ElMessage.error({ message: '数据可能已经过期了' })
          // this.newSkuStockList = this.skuStockList
          const timer = setTimeout(() => {
            this.$router.push('/reservation')
          }, 500)
          timer()
          // loading.close()
          clearTimeout(timer)
          return
        }
        this.thisStock = this.newSkuStockList[0]

        // console.log(this.newSkuStockList)

        // 规格 交货日期 =====》 提货截至时间
        this.endTime = this.newSkuStockList[0].spData[1].value // 日期更改 规格名称
        // console.log(this.skuStockList)
        for (const i of this.skuStockList) {
          if (this.endTime === i.spData[1].value) {
            this.newSkuAllList.push(i)
            this.atime.push(i.spData[0].value)
          }
        }
        // console.log(this.newSkuAllList)
        // console.log(this.endTime)
        // this.atime.push(this.endTime)

        // this.atime = calcuTime(this.previewStatus, this.endTime, this.thisBeginDay, this.thisEndDay) // 计算截至时间
        // console.log(this.atime)
        // this.tihuoValue = this.atime.beginDate //默认显示时间
        // this.timeTags.push(this.tihuoValue) // 已选的时间

        // this.atime = calcuTime(1, '2022-1-25', 2, 10)
        loading.close()
      }).catch(err => {
        console.log(err)
        // console.log(calcuTime(this.previewStatus, this.endTime, this.thisBeginDay, this.thisEndDay))
      })
  },
  setup (props, ctx) {
    return {
      headernav,
      data: ref('')
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .main{
    width: 1200px;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .header{
      margin-top: 10px;
      width: 1200px;
      // height: 400px;
      background: #FFFFFF;
      display: flex;
      .left_img{
        padding: 20px;
        border-right: 1px solid #f2f2f2;
        .detailimg{
          height: 360px;
          width: 360px;
        }
        .carousel_div{
          height: 58px;
          width: 100%;
          display: flex;
          align-items: center;
          button{
            width: 30px;
            height: 54px;
            background: #f2f2f2;
            // opacity: 0;
            transition: all 0.3s;
          }
          // button
          .car_img_ul{
            // flex: 1;
            width: 300px;
            display: flex;
            // overflow: auto;
            // justify-content: space-between;
            .car_img_li{
              flex: 1;
              .li_span_img{
                display: flex;
                // flex-shrink: 0;
                justify-content: center;
                align-items: center;
                img{
                  border: 2px solid transparent;
                  width: 90%;
                  max-width: 50px;
                  min-width: 33px;
                  min-height: 33px;
                  max-height: 50px;
                  height: 90%;
                  transition: all 0.3s;
                }
              }
              .isact_carimg{
                img{
                  border-color: #C6824E;
                }
              }
            }
          }
        }
        .carousel_div:hover{
          button{
            // opacity: 1;
            background: #EAEAEA;
          }
        }
        .el-carousel{
          .el-carousel__container{
            .el-carousel__item{
              // width: 60px;
              img{
                display: block;
                margin: 0 auto;
                width: 58px;
                height: 58px;
              }
            }
          }
        }
      }
      .center_car{
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 20px;
        .center_title{
          display: block;
          .yushouCont{
            float: left;
            min-width: 30px;
            padding: 0 5px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 4px;
            margin-right: 5px;
          }
          .titlefont{
            font-size: 18px;
            color: #333333;
          }
        }
        .el-form{
          padding: 0;
          :first-child.el-form-item{
            margin: 20px 0;
          }
          .el-form-item{
            padding: 0 20px;
            margin-bottom: 0;
            // height: 40px;
            .el-form-item__label{
              text-align-last: justify;
              text-align: left;
              color: #999999;
            }
            .el-form-item__content{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .saleclass{
                font-size: 20px;
                font-weight: bold;
                color: #C6824E;
              }
              .stock_num{
                color: #333333;
              }
              .dinghuo{
                margin-right: 50px;
                i{
                  color: #666666;
                  margin-right: 30px;
                }
              }
              .guige{
                display: flex;
                flex-wrap: wrap;
                .btn{
                  display: block;
                  float: left;
                  text-align: center;
                  height: 30px;
                  width: 75px;
                  border: 1px solid #EAEAEA;
                  margin-right: 8px;
                  margin-bottom: 8px;
                  border-radius: 2px;
                  line-height: 30px;
                  background: transparent;
                  box-shadow: none;
                  color: #333333;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .isactive{
                  border-color: #C6824E;
                  color: #C6824E;
                  background: url('../../static/imges/spanGg.png')no-repeat 62px 16px;
                }
              }
              .showallsp{
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          .itemdiv{
            height: 30px;
            background: #FFEEE0;
            padding: 0 20px;
            .itemdiv_span{
              font-size: 12px;
              line-height: 30px;
              color: #C6824E;
              strong{
                font-size: 14px;
              }
            }
          }
          .tihuo_time{
            .el-form-item__content{
              align-items: flex-start;
              .block{
                // flex: 1;
                // background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                .el-input--prefix{
                  width: 135px;
                  align-items: center;
                  input::-webkit-input-placeholder{
                    color: #999999;
                  }
                  .el-input__prefix-inner{
                    .el-icon{
                      color: #C6824E;
                    }
                  }
                }
              }
              .spanlist{
                display: flex;
                flex-wrap: wrap;
                .el-tag{
                  margin: 6px 10px 5px 0;
                }
              }
              .showallsp{
                position: absolute;
                right: -15px;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
          .yuti{
            padding:0 20px;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
            span{
              color: #999999;
              margin-right: 40px;
              i{
                color: #333333;
              }
              .el-icon{
                top: 2px;
                color: #999999;
              }
            }
          }
        }
        .btnspan{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn_div{
            float: right;
            width: 40%;
            position: relative;
            height: 52px;
            background: url('../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
            .gobutton{
              position: absolute;
              top: 12px;
              font-size: 14px;
              height: 30px;
              background: transparent;
              border-radius: 4px;
            }
            .gz{
              left: 33px;
              color: #673000;
            }
            .gm{
              left: 110px;
              color: #F2EFED;
            }
          }
        }
      }
      .right_test{
        width: 259px;
        .righttitle{
          height: 58px;
          background: #C6824E;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 58px;
          text-align: center;
        }
        .right_form{
          margin: 40px 0;
          .el-form-item{
            margin-bottom: 0;
            .el-form-item__label{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space:nowrap;
            }
            .el-form-item__content{
              .el-input--mini{
                width: 165px;
              }
            }
          }
        }
        .jisuan{
          padding: 0 15px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          p{
            // line-height: 20px;
            margin-bottom: 17px;
            color: #999999;
            span{
              color: #333333;
            }
          }
        }
        .heji{
          text-align: right;
          padding: 0 15px;
          font-size: 14px;
          color: #333333;
          span{
            display: inline-block;
            font-size: 20px;
          }
        }
      }
    }
    .detail{
      margin-top: 10px;
      display: flex;
      .supplier_message{
        width: 240px;
        height: 530px;
        margin-bottom: 50px;
        background: #FFFFFF;
        border: 1px solid #C6824E;
        padding: 0 20px;
        display: flex;
        flex-flow: column;
        h3{
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-weight: bold;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 2px solid #2B1001;
        }
        .supname{
          margin-top: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .supgood{
          margin-top: 18px;
          font-size: 12px;
          color: #C6824E;
        }
        .supxiangxi{
          flex: 1;
          margin: 20px 0;
          .xxdiv{
            display: flex;
            align-items: center;
            color: #666666;
            margin-top: 18px;
            font-size: 14px;
            line-height: 21px;
            .el-icon{
              margin-right: 5px;
              // margin-top: 3px;
            }
            .el-button{
              width: 80px;
              border: 1px solid #EAEAEA;
              color: #666666;
            }
          }
          .suplogo{
            width: 100%;
            img{
              width: 200px;
              // height: 200px;
              border-radius: 50%;
            }
          }
        }
      }
      .supplier_commodity{
        background: #FFFFFF;
        flex: 1;
        margin-left: 10px;
        padding: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        h2{
          color: #333333;
          font-size: 20px;
          margin: 10px 0 20px;
        }
        .commodity_message{
          height: 90px;
          box-sizing: border-box;
          background: #f7f7f7;
          padding: 28px 20px;
          .el-breadcrumb{
            display: flex;
            .el-breadcrumb__item{
              flex: 1;
              display: flex;
              .el-breadcrumb__inner{
                flex: 1;
                .com_body{
                  span{
                    display: block;
                    margin-bottom: 12px;
                    color: #333333;
                  }
                  p{
                    color: #999999;
                  }
                }
              }
              .el-breadcrumb__separator{
                margin-right: 15px;
                line-height: 40px;
              }
            }
          }
        }
        .commodity_content{
          .cont_message{
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-bottom: 20px;
          }
          .cont_img{
            max-width: 867px;
            overflow:visible;
            overflow: auto;
          }
        }
        .coffee_mes{
          ul{
            display: flex;
            flex-flow: wrap;
            padding: 0 20px;
            .coffee_li{
              min-height: 50px;
              width: 50%;
              display: flex;
              h4{
                line-height: 30px;
                white-space: nowrap;
              }
              span{
                line-height: 30px;
                color: #666666;
                word-break: break-all;
              }
            }
            .coli1{
              width: 100%;
            }
          }
        }
      }
    }
  }
 .divfooter{
    background: #280E01;
  }
}
</style>
